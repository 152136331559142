import { createSlice } from '@reduxjs/toolkit';


const initialState = {
  id: "",
  registered: [],
  name: "",
  country: "",
  city: "",
  legal_type: "",
  status: "",
  incorporation_year: null,
  industry: "",
  jurisdiction: "",
  num_employees: null,
  annual_turnover: null,
  monthly_txns: null ,
  taxable_income: null ,
  taxable_expenses: null ,
  visa_city: null,
  branches: false,
  establishment: null,
  tax_group: false,
  goods_vendor: false,
  service_vendor: false,
  cross_border_txns: false,
  is_default: false,
  profile_complete: false
};

const accountSettingSlice = createSlice({
  name: 'accountSlice',
  initialState,
  reducers: {
    // setRegistered: (state, action) => {
    //   state.registered = action.payload;
    // },
    // setName: (state, action) => {
    //   state.name = action.payload;
    // },
    // setCountry: (state, action) => {
    //   state.country = action.payload;
    // },
    // setCity: (state, action) => {
    //   state.city = action.payload;
    // },
    // setLegalType: (state, action) => {
    //   state.legal_type = action.payload;
    // },
    // setStatus: (state, action) => {
    //   state.status = action.payload;
    // },
    // setIncorporationYear: (state, action) => {
    //   state.incorporation_year = action.payload;
    // },
    // setIndustry: (state, action) => {
    //   state.industry = action.payload;
    // },
    // setJurisdiction: (state, action) => {
    //   state.jurisdiction = action.payload;
    // },
    // setNumEmployees: (state, action) => {
    //   state.num_employees = action.payload;
    // },
    // setAnnualTurnover: (state, action) => {
    //   state.annual_turnover = action.payload;
    // },
    // setMonthlyTxns: (state, action) => {
    //   state.monthly_txns = action.payload;
    // },
    // setTaxableIncome: (state, action) => {
    //   state.taxable_income = action.payload;
    // },
    // setTaxableExpenses: (state, action) => {
    //   state.taxable_expenses = action.payload;
    // },
    // setVisaCity: (state, action) => {
    //   state.visa_city = action.payload;
    // },
    // setBranches: (state, action) => {
    //   state.branches = action.payload;
    // },
    // setEstablishment: (state, action) => {
    //   state.establishment = action.payload;
    // },
    // setTaxGroup: (state, action) => {
    //   state.tax_group = action.payload;
    // },
    // setGoodsVendor: (state, action) => {
    //   state.goods_vendor = action.payload;
    // },
    // setServiceVendor: (state, action) => {
    //   state.service_vendor = action.payload;
    // },
    // setCrossBorderTxns: (state, action) => {
    //   state.cross_border_txns = action.payload;
    // },
    // setIsDefault: (state, action) => {
    //   state.is_default = action.payload;
    // },
    // setProfileComplete: (state, action) => {
    //   state.profile_complete = action.payload;
    // }

    setProfileEntity: (state, action) => {
      // console.log(action.payload)
      return {...action.payload}
  },
  setUpdateProfileEntity: (state, action) => {
      return {...state, ...action.payload}
  },

  }
});

export const {
  // setRegistered,
  // setName,
  // setCountry,
  // setCity,
  // setLegalType,
  // setStatus,
  // setIncorporationYear,
  // setIndustry,
  // setJurisdiction,
  // setNumEmployees,
  // setAnnualTurnover,
  // setMonthlyTxns,
  // setTaxableIncome,
  // setTaxableExpenses,
  // setVisaCity,
  // setBranches,
  // setEstablishment,
  // setTaxGroup,
  // setGoodsVendor,
  // setServiceVendor,
  // setCrossBorderTxns,
  // setIsDefault,
  // setProfileComplete
  setProfileEntity,
  setUpdateProfileEntity
} = accountSettingSlice.actions;

export default accountSettingSlice.reducer;
